<template>
  <v-container>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row v-if="loaded">
      <v-col :cols="12/weddingStatus.length" v-for="status of weddingStatus" :key="status.id"
             :style="'background-color: '+getColor(status.position)">
        <h4 class="pb-8">{{ status.name }}</h4>
        <draggable
            class="dragArea list-group"
            :list="weddings[status.name]"
            :group="{ name: status.name,  put: true }"
            :clone="cloneDog"
            @end="log"
            @change="log"

        >
          <div class="list-group-item" v-for="element in weddings[status.name]" :key="element.id">
            <v-card flat class="mb-3 rounded-xl" @click="openWedding(element.id)" style="background-color: #F3F4F3">

              <v-card-title class="mb-0 pb-0">

                <v-icon class="pe-2">mdi-ring</v-icon>
                <h6>{{ element.customerFirstName }} und {{ element.customerPartnerName }}</h6>
              </v-card-title>
                        <v-container class="mt-0 pt-0 mb-0 pb-0">
                          <v-row class="mt-0 pt-0 mb-0 pb-0 ms-0 me-1">
                            <v-col cols="3" class="pa-1" v-for="label of element.labels" :key="label.name">

                              <v-tooltip bottom :color="getFullLabel(label).color" max-width="200px">
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <div class="rounded-xl " style="min-height: 8px" :style="'background-color: '+getFullLabel(label).color">

                                    </div>
                                  </span>
                                </template>
                                {{getFullLabel(label).name}}
                              </v-tooltip>
                            </v-col>
                        </v-row>
                        </v-container>

              <v-card-text class="mt-2 pt-2">
                <b>{{ element.type }}</b><br>
                {{ getStringDate(element.wedDate) }}<br>

              </v-card-text>
            </v-card>
          </div>
        </draggable>
      </v-col>
    </v-row>
    <v-row v-else class="justify-center">
      <Spinner >

      </Spinner>
    </v-row>
    <v-row v-if="totalLength - getWeddingWithSStatusCount() !== 0" class="text-center">
      <v-col>
        <p>
          <span v-if="totalLength - getWeddingWithSStatusCount() ===1">Es gibt <b>eine</b> Hochzeit ohne Status</span>
          <span v-else>Es gibt <b>{{ totalLength - getWeddingWithSStatusCount() }}</b> Hochzeiten ohne Status</span>
          <br>
          <a @click="$emit('changeTab')">Alle Hochzeiten anzeigen</a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
import draggable from 'vuedraggable'
import {mapGetters} from "vuex";
import {date2String, error, msgObj} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const Spinner = () => import("@/components/generalUI/Spinner");

export default {
  name: "DraggableView",
  components: {
    draggable,
    Message,
    Spinner
  },
  computed: {
    ...mapGetters('settings', {
      weddingStatus: 'weddingStatus',
      weddingLabels: 'weddingLabels',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('wedding', {
      weddings: 'weddingsByStatus',
    }),
  },
  data() {
    return {
      myArray: [{id: "123", name: "test"}],
      drag: false,
      lists: {},
      message: msgObj(),
      loaded: false
    }
  },
  props: {
    totalLength: Number
  },
  mounted() {
    this.$store.dispatch('settings/getStatus', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = error(err)
    }).then(() => {
      this.$store.dispatch('wedding/getWeddingsByStatus', {
        uid: this.user.id,
      }).catch((err) => {
        this.message = error(err)
      }).then(()=>{
        this.$store.dispatch('settings/getWeddingLabels', {
          uid: this.user.id,
        }).catch((err) => {
          this.message = error(err)
        }).then(()=>{
          this.loaded= true
        })
      })
    })
  },
  methods: {
    getFullLabel(name){
      for(let label of this.weddingLabels){
        if(label.name === name){
          return label
        }
      }
      return {
        name: 'Gelöschtes Label',
        color: 'grey'
      }
    },
    getWeddingWithSStatusCount() {
      let i = 0
      if(this.weddingStatus){
        for (let status of this.weddingStatus) {
          for (let wedding of this.weddings[status.name]) {
            if(wedding){
              i++
            }
          }
        }
      }

      return i
    },
    log: function (evt) {
      if (evt.removed) {
        for (let status of this.weddingStatus) {
          for (let wedding of this.weddings[status.name]) {
            if (wedding.id === evt.removed.element.id) {
              this.$store.dispatch('customer/editField', {
                entity: "wedding",
                uid: this.user.id,
                id: wedding.id,
                newValue: status.name, //neuer Status
                row: 'status'
              }).catch((err) => {
                this.message = error(err)
              })
            }
          }
        }
      }
    },
    getColor(position) {
      if (position % 2 === 0) {
        return '#FFFFFF'
      } else {
        return '#FFFFFF'
      }
    },
    cloneDog(payload) {
      return payload;
    },

    getStringDate(date) {
      return date2String(date)
    },
    openWedding(id) {
      this.$router.push('weddings/wedding/' + id)
    }
  }
}
</script>
